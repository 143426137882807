<template>
  <ui-component-modal
    :modalTitle="'Edit day percentage settings'"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :onClickCancel="onClickCancel"
    :onClickSave="saveYieldSettings"
    :hideSaveButton="isSavingError || isSavingSuccess"
    :buttonLabelCancel="isSavingError || isSavingSuccess ? 'Close' : 'Cancel'"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr
            v-for="(dayPercentage, index) in mYieldSettings.DayPercentages"
            :key="index"
          >
            <td>
              <span>{{ dayPercentage.Day | getDayName }}</span>
            </td>
            <td>
              <input
                type="number"
                v-model.number="dayPercentage.Percentage"
                class="input"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mYieldSettings: null,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsState']),

    saveYieldSettings() {
      let self = this
      self.isSaving = true

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      yieldProvider.methods
        .updateLocationYieldSettings(self.mYieldSettings)
        .then((response) => {
          if (response.status === 200) {
            self.setYieldSettingsState(self.yieldSettingsState)

            self.onClickCancel()
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
